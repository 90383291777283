<template>
   <section id="page-tenant">
      <v-app-bar v-if="activity.step === 1" dark flat elevation="0" color="primary" height="68">
         <v-spacer />
         <v-toolbar-title class="text-title-1 font-weight-bold text-uppercase mx-9">
            Tenant Registration
         </v-toolbar-title>
         <v-spacer />
      </v-app-bar>
      <n-registration-form :activity="activity" :organizations="organizations" :floors="floors" @select="selectRecord" @update="updateRecord" />
      <!-- :badges="badges" :tenants="tenants" :reasons="reasons" @select="selectRecord" -->
   </section>
</template>

<script>
import { flatten, encrypt, decrypt } from '@/utils/nirtara'
import { receptionist } from '@/utils/fields.js'
export default {
   name: 'SectionPageTenant',

   components: {
      NRegistrationForm: () => import('@/components/tenant/NRegistrationForm'),
   },

   data: () => ({
      isUsed: false,
      activity: { step: 0, id: 0, kind: '', language: 'English' },
      organizations: [],
      floors: [],
   }),

   created () {
   },
   mounted () {
      // console.log(`mounted ( telegram = ${decrypt(this.$route.params.telegram)} )`) // 427518496
      // this.selectRecord()
   },
   destroyed () {
   },
   methods: {
      async selectRecord () {
         var data = {
            conditions: { kind: 'Receptionist', zone: '*', 'object.telegram': decrypt(this.$route.params.telegram) },
            headers: { command: 'selectPerson' },
            // options: { limit: skip ? 600 : 60, skip: skip || 0 },
         }
         // console.log('selectRecord ()', JSON.parse(JSON.stringify(data)))
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('selectRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         if (response.data.Message) this.$store.commit('snackNotification', response.data)
         else if (response.data.length === 1) {
            const json = response.data[0]
            // console.log(`selectRecord ( status = ${json.Person[0].status} )`, JSON.parse(JSON.stringify(json)))

            const floors = json.Person[0][json.kind].filter(j => j.Floor)
            json.Person[0][json.kind] = json.Person[0][json.kind].filter(j => !j.Floor)
            Object.assign(this.activity, receptionist, flatten(json), { Floors: floors }) // , step: 1

            this.floors = await this.selectParent(
               { kind: 'Floor' },
               { _id: 0, identity: 1, name: '$object.name' },
               'Location',
            )
            // console.log(`selectRecord ( this.floors = ${this.floors.length} )`, JSON.parse(JSON.stringify(this.floors)))
            this.organizations = await this.selectParent(
               { kind: 'Organization' },
               // { kind: 'Organization', zone: '*' },
               { _id: 0, identity: 1, name: '$object.name', Floors: '$array' },
               'Subject',
               { limit: 1000 },
            )
            // console.log(`selectRecord ( this.organizations = ${this.organizations.length} )`, JSON.parse(JSON.stringify(this.organizations)))
         } else setTimeout(() => { this.activity.step = -1 }, 999)
      },
      selectParent (conditions, projection, type, options) {
         return new Promise((resolve) => {
            var data = {
               conditions: conditions ?? {},
               projection: projection ?? {},
               options: options ?? {},
               headers: { command: `select${type ?? 'Option'}` },
            }
            this.$store.dispatch('mongoose', data)
            .then(response => resolve(response.data.map(json => projection ? json : flatten(json)))).catch(() => resolve([]))
         })
      },
      async updateRecord (item) {
         const images = []
         item.Card && images.push({ Card: [item.Card] }) // { Card: item.Card ? [`${item.Card.substr(0, 32)}....`] : [] },
         item.Face && images.push({ Face: [item.Face] }) // { Face: item.Face ? [`${item.Face.substr(0, 32)}....`] : [] },
         var array = item.Floors
         var data = {
            update: {
               id: item.id,
               site: item.site,
               kind: item.kind,
               zone: item.zone,
               identity: item.identity,
               array: array,
               object: {
                  [item.kind]: images,
                  name: item.name,
                  // below added for receptionist
                  telegram: item.telegram,
                  organization: item.organization,
                  phone: item.phone,
                  email: item.email,
                  password: encrypt(item.password),
                  status: 'Submit',
               },
            },
            headers: { command: 'updatePerson' },
         }
         // console.log('updateRecord ()', '\nitem:', JSON.parse(JSON.stringify(item)), '\ndata:', JSON.parse(JSON.stringify(data)))
         // if (data) return
         const response = await this.$store.dispatch('mongoose', data)
         // console.log('updateRecord (response.data)', JSON.parse(JSON.stringify(response.data)))
         response.data.Message && this.$store.commit('snackNotification', response.data)
         if (response.data.Person) {
            this.activity.step = 2
            // this.$store.commit('snackNotification', { Message: [{ Success: [], note: `${response.data.kind} updated successfully` }], kind: 'Success' })
            this.$store.commit('snackNotification', { Message: [{ Success: [], note: 'Registration succeed' }], kind: 'Success' })
         }
      },
   },
}
</script>

<style lang="scss" scoped>
</style>
